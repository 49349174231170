import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import './gameOfLife.css';
import initialization from './gameOfLife.json';

const GameOfLife = () => {
    // sets the size of the grid
    const cols = 120;
    const rows = 120;
    // selected starting point for popular models
    const [ configurationSelection, setConfigurationSelection ] = useState('Test Seed');
    const startChoices = Object.keys(initialization);
    // '
    const  [ grid, setGrid ] = useState();
    const [ start, setStart ] = useState();
    const startRef = useRef(start);
    startRef.current = start;
    const [ waitTimer, setWaitTimer ] = useState(1000);
    const [ generation, setGeneration ] = useState(0);

    const positions = [
        [-1, -1],
        [0, -1],
        [1, -1],
        [-1, 0],
        [1, 0],
        [-1, 1],
        [0, 1],
        [1, 1]
    ];

    const blankGrid = () => {
        const tempGrid = [];
        for (let i = 0; i < rows; i++){
            const row = [];
            for (let j = 0; j < cols; j++) {
                row.push(0)
            }
            tempGrid.push(row);
        }
        return tempGrid
    }

    const randomGrid = () => {
        const tempGrid = [];
        for (let i = 0; i < rows; i++){
            const row = [];
            for (let j = 0; j < cols; j++) {
                row.push(Math.floor(Math.random() * 2))
            }
            tempGrid.push(row);
        }
        return tempGrid
    }

    const userSelectedGrid = useCallback(() => {
        const gridCenter = [ Math.floor(rows / 2), Math.floor(cols / 2) ];
        const tempGrid = [];
        for (let i = 0; i < rows; i++){
            const row = [];
            for (let j = 0; j < cols; j++) {
                row.push(0)
            }
            tempGrid.push(row);
        }
        initialization[configurationSelection].forEach((pixelCoordinates) => {
            tempGrid[gridCenter[0] + pixelCoordinates.X][gridCenter[1] + pixelCoordinates.Y] = 1;
        })
        return tempGrid
    }, [configurationSelection]);

    useEffect(() => {
        setGrid(randomGrid())
    }, []);

    useEffect(() => {
        setGrid(userSelectedGrid())
    }, [userSelectedGrid]);

    const changePixel = useCallback((e, i, j) => {
        const tempGrid = grid;
        if (tempGrid[i][j]) {
            tempGrid[i][j] = 0;
        } else {
            tempGrid[i][j] = 1;
        }
        if (e.target.classList[1] === "on") {
            e.target.classList.remove("on");
            e.target.classList.add("off");
        } else {
            e.target.classList.remove("off");
            e.target.classList.add("on");
        }
        setGrid(tempGrid);
    }, [grid]);

    function runSimulation() {
        if (!startRef.current) {
            return
        }
        console.log("waitTimer:  ", waitTimer);

        setGrid((g) => {
            const next = g.map((row, i) => {
                return row.map((cell, j) => {
                    let sum = 0
                    positions.forEach((position) => {
                        const x = i + position[0];
                        const y = j + position[1];
                        if (x >= 0 && x < rows && y >= 0 && y < cols) {
                            sum += g[x][y];
                        }
                    });
                    if (sum < 2 || sum > 3) {
                        return 0;
                    }
                    if (sum === 3) {
                        return 1;
                    }
                    return g[i][j]
                })
            })
            return next
        });
        setGeneration((c) => ++c);      
    }
    
    //  Loads a new starting configuration for the simulation
    const ReinitializeGrid = (selection) => {
        setConfigurationSelection(selection.target.value);
    };

    const population = useMemo(() => {
        if (grid) {
            return grid.reduce((acc, row) => {
                return acc + row.reduce((rowAcc, value) => rowAcc + value, 0);
            }, 0);
        }
    },[grid]);

    return(
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}
        >
            <div
                style={{
                display: "flex",
                spaceBetween: "10px",
                margin: ".025rem",
                justifyContent: "center",
                alignItems: "center",
                }}
            >
                <button onClick={() => setWaitTimer((time) => time + 100)}>Slower</button>
                <button 
                    onClick={() => {
                        setStart(!start);
                        if (!start) {
                            clearInterval()
                            startRef.current = true;
                        }
                        setInterval(() => runSimulation(grid), waitTimer)
                    }}
                >
                    {start ? "Stop" : "Start"}
                </button>
                <button onClick={() => setWaitTimer(
                        (time) => {
                            if (time > 0) {
                                return time - 100
                            } else {
                                return 0
                            }
                        }
                    )}
                >
                    Faster
                </button>
                <button 
                    onClick={() => {setGrid(randomGrid); setGeneration(0)}}
                >
                    Random
                </button>
                <button 
                    onClick={() => {setGrid(blankGrid); setGeneration(0)}}
                >
                    Blank
                </button>

            </div>
            <div className="grid" style={{ width: cols * 5 }}>
                {grid && 
                    grid.map((rows, i) => 
                        rows.map((col,k) => (
                            <div className={grid[i][k] ? "box on" : "box off"} 
                                key={i + '_' + k}
                                onClick={(e) => {changePixel(e,i,k)}}
                            />
                        ))
                    )
                }
            </div>
            <p>Generation:  {generation}, Growth Rate:  {100 - waitTimer/100}, Population: {population}</p>
            <div className="selector">
                <select name="start" id="start" value={configurationSelection} onChange={(item) => {ReinitializeGrid(item); setGeneration(0)}}>
                    {startChoices.map((choice) => <option key={choice} value={choice}>{choice}</option>)}
                </select>
            </div>
        </div>
    )
}

export default GameOfLife