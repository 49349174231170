import { useState, useMemo } from 'react';
import './DBApp.css';
import catalogue from './CompleteList.json';
import AlbumCabinet from './album-cabinet';
import AudioPlayer from './player';
import SongListDisplay from './songlist';

function DBApp() {
  const [cabinetState, setCabinetState] = useState(false);

  //                 Collect The Titles                  //
  const artistAlbumKeys = Array.from(new Set(catalogue.map((item) => (item["artist"] + '-' + item["album"])).sort()));
  const [albumSelection, setAlbumSelection] = useState(artistAlbumKeys[14]);

  //   Catalogue All The Albums and sort them   //
  const artistBins = useMemo(() => {
    let artistAlbumArray = {};
    catalogue.forEach((value) => {
      let currentArtist = value.artist;
      let currentAlbum = value.album;
      let currentAlbumCover = value.artistFolder + '/' + value.albumFolder + '/' + value.cover;
      let currentArtistAlbumKey = (value.artist + '-' + value.album);
      if (!artistAlbumArray[currentArtist]) {
        artistAlbumArray[currentArtist] = [];
      } else if (!artistAlbumArray[currentArtist][currentArtistAlbumKey]) {
        artistAlbumArray[currentArtist][currentArtistAlbumKey] = [currentAlbum, currentAlbumCover];
      };
    });

    return artistAlbumArray;

  },[]);

  //   Catalogue All The Tracks and sort them   //
  const artistAlbumTracks = useMemo(() => {
    let artistTrackArray = {};
    catalogue.forEach((value) => {
      let currentArtistAlbumKey = (value.artist + '-' + value.album);
      let currentTrackDetails = { 
        "track":value.track, 
        "song":value.song, 
        "artist":value.artist, 
        "album":value.album, 
        "location": (value.artistFolder + '/' + value.albumFolder), 
        "file":value.file, 
        "cover":value.cover 
      };
      if (!artistTrackArray[currentArtistAlbumKey]) {
        artistTrackArray[currentArtistAlbumKey] = [];
      }
      artistTrackArray[currentArtistAlbumKey].push(currentTrackDetails);

    });

    return artistTrackArray;

  }, []);

  const cabinetChange = () => {
    if (cabinetState) {
      setCabinetState(false);
    } else {
      setCabinetState(true)
    }
  };  

  const playerSongList = useMemo(() => artistAlbumTracks[albumSelection], [albumSelection, artistAlbumTracks]);
  const [songSelection, setSongSelection] = useState(0);
  const [ flipOrder, setFlipOrder ] = useState(false);

  return (
    <div className="system">
      <div className="album-selector">
        <div className="cabinet-controller">
            <button id="cabinet-latch" onClick={cabinetChange}>Album Selector</button>
        </div>
        {cabinetState && <AlbumCabinet {...{ artistBins, setAlbumSelection, setCabinetState }}/>}
      </div>
      <div className="media-player">
        <AudioPlayer {...{ artistAlbumKeys, albumSelection, setAlbumSelection, songSelection, setSongSelection, playerSongList, flipOrder }}/>
      </div>
      <SongListDisplay {...{ albumSelection, setSongSelection, playerSongList, flipOrder, setFlipOrder }}/>
    </div>
  );
}

export default DBApp;
