import { useState, useRef } from 'react';
import Controls from "./controls"
import DisplayTrack from "./displayTrack"
import ProgressBar from "./progressBar"

function AudioPlayer ({ artistAlbumKeys, albumSelection, setAlbumSelection, songSelection, setSongSelection, playerSongList, flipOrder}) {
    const audioRef = useRef();
    const progressBarRef = useRef();
    const [songProgress, setSongProgress] = useState(0);
    const [songDuration, setSongDuration] = useState(0);

    const currentTrack = playerSongList[songSelection];
    const totalTracks = playerSongList.length;
    const [isLoaded, setIsLoaded] = useState(false);

    const getDuration = () => {
        const seconds = audioRef.current.duration;
        setSongDuration(seconds);
        audioRef.current.max = seconds;
    }

    const handleEnd = () => {
        if (flipOrder) {
            if (songSelection > 0) {
                setSongSelection(songSelection - 1);
            }
        } else {
            if (songSelection < totalTracks - 1) {
                setSongSelection(songSelection + 1);
            }
        }
        setIsLoaded(false);
    };

    const handleLoadedData = () => {
      setIsLoaded(true);
      audioRef.current.play();
    };

    return (
        <div className="player">
            <DisplayTrack {...{ artistAlbumKeys, albumSelection, setAlbumSelection, currentTrack, setSongSelection }} />
            <Controls {...{ audioRef, totalTracks, songSelection, setSongSelection, progressBarRef, setSongProgress, flipOrder }} />
            <ProgressBar {...{ audioRef, progressBarRef, songProgress, songDuration }} />
            <div className="audio-player">
                <audio 
                    ref={ audioRef } 
                    src={`./music/${playerSongList[songSelection].location}/${playerSongList[songSelection].file}`} 
                    onLoadedData={handleLoadedData} 
                    onLoadedMetadata={getDuration} 
                    onEnded={handleEnd} 
                    autoPlay={isLoaded}
                />
            </div>
        </div>
    )
}

export default AudioPlayer