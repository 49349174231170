function DisplayTrack ( { artistAlbumKeys, albumSelection, setAlbumSelection, currentTrack, setSongSelection } ) {
    const currentAlbumPosition = artistAlbumKeys.indexOf(albumSelection);
    const lastAlbum = artistAlbumKeys.length;

    const previousAlbum = () => {
        setSongSelection(0);
        setAlbumSelection(artistAlbumKeys[currentAlbumPosition - 1]);
    }

    const nextAlbum = () => {
        setSongSelection(0)
        setAlbumSelection(artistAlbumKeys[currentAlbumPosition + 1]);
    }

    return(
        <>
            <div className="cover">
                <div className="album-controls">
                    {(currentAlbumPosition !== 0) && <button onClick={previousAlbum}><small>previous album</small></button>}
                </div>
                <div className="album-cover">
                    <img id="cover-img" src={`./music/${currentTrack.location}/${currentTrack.cover}`} alt="Album cover" />
                </div>
                <div  className="album-controls">
                    {(currentAlbumPosition < lastAlbum - 1) && <button onClick={nextAlbum}><small>next album</small></button>}
                </div>
            </div>            
            <div className="info">
                <div className="labels">
                    <p><small>Track: </small></p>
                    <h2><small>Song: </small></h2>
                    <h3><small>Album: </small></h3>
                    <h4><small>Artist: </small></h4>
                </div>
                <div className="details">
                    <p><small>{currentTrack.track}</small></p>
                    <h2>{currentTrack.song}</h2>
                    <h3>{currentTrack.album}</h3>
                    <h4>{currentTrack.artist}</h4>
                </div>
            </div>
        </>
    )
}

export default DisplayTrack