import { useState, useRef, useEffect, useCallback } from 'react';

function Controls ({ audioRef, totalTracks, songSelection, setSongSelection, progressBarRef, setSongProgress, flipOrder }) {
    const [isPlaying, setIsPlaying] = useState(true);
    const [isMuted, setIsMuted] = useState(false);
    const playAnimationRef = useRef();

    const repeat = useCallback(() => {
        const currentTime = audioRef?.current?.currentTime;
        setSongProgress(currentTime);
        if (currentTime) {
            progressBarRef.current.value = currentTime;
            progressBarRef.current.style.setProperty(
                '--range-progress:',
                `${progressBarRef.current.value}%`
            );
        }
      
        playAnimationRef.current = requestAnimationFrame(repeat);
    }, [audioRef, progressBarRef, setSongProgress]);

    useEffect(() => {
        if (isPlaying) {
          audioRef.current.play();
        } else {
          audioRef.current.pause();
        }
        playAnimationRef.current = requestAnimationFrame(repeat);
    }, [isPlaying, audioRef, repeat]);

    const playSong = () => {
        audioRef.current.play()
        setIsPlaying(true);
    }

    const pauseSong = () => {
        audioRef.current.pause()
        setIsPlaying(false);
    }

    const togglePlayPause = () => {
        if (audioRef.current.paused) {
            playSong();
        } else {
            pauseSong();
        }
    }

    const previousSong = () => {
        if (flipOrder) {
            if (songSelection < totalTracks - 1) {
                setSongSelection(songSelection + 1);
            }
        } else {
            if (songSelection > 0) {
                setSongSelection(songSelection - 1);
            }
        };
        if (audioRef.current.paused) {
            playSong();
        };
    }
    
    const rewind = () => {
        audioRef.current.currentTime -= 10;
    }

    const fastForward = () => {
        audioRef.current.currentTime += 10;
    }

    const nextSong = () => {
        if (flipOrder) {
            if (songSelection > 0) {
                setSongSelection(songSelection - 1);
            }
        } else {
            if (songSelection < totalTracks - 1) {
                setSongSelection(songSelection + 1);
            }
        };
        
        if (audioRef.current.paused) {
            playSong();
        };
    };

    const toggleMute = () => {
        if (audioRef.current.muted) {
            audioRef.current.muted = false;
            setIsMuted(false);
        } else {
            audioRef.current.muted = true;
            setIsMuted(true);
        }
    };

    const volume = (value) => {
        audioRef.current.volume = value;
    };

    return (
        <>
            <div className="controls">
                <button className="control-buttons" id="previous-icon" onClick={previousSong}>
                    <img src=".\images\previous.png" alt="Previous" className="playback-controls"/>
                </button>
                <button className="control-buttons" id="rewind" onClick={rewind}>
                    <img src=".\images\rewind.png" alt="Rewind" className="playback-controls"/>
                </button>
                <button className="control-buttons" id="play-icon" onClick={togglePlayPause}>
                    {audioRef?.current?.paused ? <img src=".\images\play.png" alt="Play" className="playback-controls"/> : <img src=".\images\pause.png" alt="Pause" className="playback-controls"/>}
                </button>
                <button className="control-buttons" id="fastforward" onClick={fastForward}>
                    <img src=".\images\fast_forward.png" alt="Fast Forward" className="playback-controls"/>
                </button>
                <button className="control-buttons" id="forward-icon" onClick={nextSong}>
                    <img src=".\images\next.png" alt="Next" className="playback-controls"/>
                </button>
            </div>
            <div className="volume">
                <button id="mute" onClick={toggleMute}>
                    {isMuted ? <img src=".\images\mute.png" alt="Mute" className="button-images"/>:<img src=".\images\speaker.png" alt="Unmute" className="button-images"/>}
                </button>
                <input type="range" id="volume-bar" min="0" max="1" step="0.01" onChange={(e) => volume(e.target.value)} style={{
                        background: `linear-gradient(to right, #f50 ${volume}%, #ccc ${volume}%)`,
                    }}>
                </input>  
                <button id="download">
                    {audioRef.current ? <a href={audioRef.current.currentSrc} download><img src=".\images\download.png" alt="Download" className="button-images"></img></a> : null}
                </button> 
            </div>
        </>
    )
}

export default Controls