
function SongListDisplay ({ albumSelection, setSongSelection, playerSongList, flipOrder, setFlipOrder }) {

    const reversePlayerSongList = playerSongList.toReversed();

    const playThis = (songNumber) => {
        setSongSelection(songNumber)
    };

    return(
        <div className="songListDisplay">
          <div className="playlist">
              <h2>{albumSelection.replace("-", " - ")}</h2>
              <button id="reverse" onClick={() => setFlipOrder(!flipOrder)}>Reverse Order</button>
              {(!flipOrder) && <ul id="song-list">
                  {playerSongList.map((track, index) => <li key={track.song} onClick={() => playThis(index)}>{track.track} - {track.song}</li>)}
              </ul>}
              {(flipOrder) && <ul id="song-list">
                  {reversePlayerSongList.map((track, index) => <li key={track.song} onClick={() => playThis((reversePlayerSongList.length - 1) - index)}>{track.track} - {track.song}</li>)}
              </ul>}
          </div>
        </div>
    );
}

export default SongListDisplay