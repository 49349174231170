function ProgressBar ( { audioRef, progressBarRef, songProgress, songDuration } ) {

    const formatTime = (time) => {
        if (time && !isNaN(time)) {
          const minutes = Math.floor(time / 60);
          const formatMinutes =
            minutes < 10 ? `0${minutes}` : `${minutes}`;
          const seconds = Math.floor(time % 60);
          const formatSeconds =
            seconds < 10 ? `0${seconds}` : `${seconds}`;
          return `${formatMinutes}:${formatSeconds}`;
        }
        return '00:00';
    };

    // Update the progress bar
    const updateProgress = () => {
        audioRef.current.currentTime = progressBarRef.current.value;
    }

    return (
        <div className="progress">
            <span>{formatTime(songProgress)}  </span>
            <div className="progress-bar">
              <input type="range" ref={progressBarRef} defaultValue="0" max={songDuration} onChange={updateProgress} />
            </div>            
            <span>  {formatTime(songDuration)}</span>
        </div>
    )
}

export default ProgressBar