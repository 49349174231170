function AlbumCabinet ({ artistBins, setAlbumSelection, setCabinetState }) {

    const albumSelected = (selection) => {
        setAlbumSelection(selection); 
        if (selection) {
            setCabinetState(false);
        }
    };

    return <div id="album-cabinet">
        {Object.keys(artistBins).sort().map((artist) => (            
            <div className="artist" key={artist.trim()}>
                <h1>{artist}</h1>
                <div className="artist-albums">
                    {Object.keys(artistBins[artist]).sort().reverse().map((album) => 
                            <div key={album.trim()} className="album-display">
                                <img className="thumbnail" src={`./music/${artistBins[artist][album][1]}`} alt="Album cover" onClick={() => albumSelected(album)}/>
                                <p>
                                    {artistBins[artist][album][0]}
                                </p>
                            </div>
                        )
                    }
                </div>
            </div>
        ))}
    </div>
}

export default AlbumCabinet